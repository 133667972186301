//// ==========================================================================
//// foundation
//// ==========================================================================
//@import "_foundation/_reset";
@import "_foundation/_variable";
//@import "_foundation/_base";

//// ==========================================================================
//// structure
//// ==========================================================================
@import "_structure/_st-header";
@import "_structure/_st-footer";


//// ==========================================================================
//// component
//// ==========================================================================


//// ==========================================================================
//// lib
//// ==========================================================================


//// ==========================================================================
//// page
//// ==========================================================================

body{
  background-color: #0e1b25 !important;
}

#wrapper{
  overflow: hidden;
  background-color:#fff;
  opacity: 0;
}


.hd_l-inner{
  opacity: 0;
}

/* edge */
@supports (-ms-ime-align: auto) {
  .hd_map-btn{
    a{
      color:#0e1b25;
    }
  }
}

/* IE11 */
_:-ms-lang(x)::-ms-backdrop, .hd_map-btn a {
  color:#0e1b25;
}


//*******************************************************************
// MV
//*******************************************************************

.view-wrap{
  background-color: #0e1b25;
}

#pixiview{
  position:relative;
  z-index:1;
  opacity: 0;
}

canvas{
  width:100%;
  height: 100%;
  min-width:$minW;
  position: relative;
  @include mq-sp{
    min-width:100%;
  }
}

.mv{
  width:100%;
  min-width: $minW;
  height: 100vh;
  position:absolute;
  top:0;
  left:0;
  //pointer-events: none;
  background-color: transparent;
  z-index:2;
  @include mq-sp{
    min-width:100%;
  }
}

.mv_logo{
  width:359px;
  height:156.5px;
  margin:0 auto;
  position:absolute;
  top:50%;
  left:50%;
  transform: translateX(-50%) translateY(-50%);
  z-index:1;
  opacity: 0;
  @include mq-sp{
    width:pxToVw(718px);
    height: pxToVw(313px);
  }
}

//*******************************************************************
// WORKS
//*******************************************************************

.work{
  width:100%;
  min-width: $minW;
  overflow: hidden;
  padding-top:100px;
  padding-bottom:106px;
  background-color: #c0344b;
  position:relative;
  @include mq-sp{
    //min-width: 100%;
    min-width: 100vw;
    padding-top:pxToVw(150px);
    padding-bottom: pxToVw(346px);
  }
  &::before{
    content:"";
    display: block;
    width:100%;
    height: 100%;
    background-color: #fff;
    position:absolute;
    top:0;
    left:calc(50% + 300px);
    @include mq-sp{
      width:pxToVw(200px);
      left:auto;
      right:0;
    }
  }
}

.work_l-ttl-wrap{
  width:$minW;
  margin:0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom:47.5px;
  @include mq-sp{
    width:pxToVw(1025px);
    padding-bottom:pxToVw(152px);
  }
}


.work_ttl{
  width:471px;
  mix-blend-mode: difference;
  @include mq-sp{
    width:pxToVw(611px);
  }
}

.work_more-btn{
  z-index:1;
  font-size: 1.8rem;
  @include mq-sp{
    font-size:pxToVw(30px);
  }
  a{
    display: inline-block;
    text-decoration: underline;
    padding:0 8px;
    font-family: "NotoSerif", serif;
    letter-spacing: 0.05em;
    @include hoverOpacity;
    @include mq-sp{
      padding:0;
      letter-spacing: 0;
    }
  }
}

.work_l-list-wrap{
  padding-top:450px;
  position:relative;
  @include mq-sp{
    padding-top: pxToVw(491px);
  }
}

.work_list-wrap{
  width:2400px;
  position: absolute;
  top:0;
  left:calc(50% - 100px);
  //transform: translateX(calc(-50% + -100px));
  transform: translateX(-50%);
  @include mq-sp{
    width:pxToVw(2622px);
    left:pxToVw(-823px);
    transform: translateX(0);
  }
}

.work_list{
  width:100%;
  display: flex;
  flex-direction: row;
}

.work_list-item{
  width:800px;
  position:relative;
  @include mq-sp{
    width:pxToVw(874px);
  }
  a{
    display: block;
  }
  &::before{
    content:"";
    display: block;
    width:100%;
    height: 100%;
    position:absolute;
    top:0;
    left:0;
    z-index:1;
    background-color: rgba(14,27,37,0.8);
    transition:all .6s cubic-bezier(0.215, 0.61, 0.355, 1);
    pointer-events: none;
  }
  &.active{
    &::before{
      background-color: transparent;
    }
  }
}

.work_name-list-wrap{
  width:2400px;
  height: 106px;
  position:absolute;
  bottom:0;
  left:calc(50% - 100px);
  //transform: translateX(calc(-50% + -100px));
  transform: translateX(-50%);
  background-color: #c0344b;
  @include mq-sp{
    width:pxToVw(2622px);
    height: pxToVw(346px);
    left:pxToVw(-823px);
    transform: translateX(0);
  }
  &::before{
    content:"";
    display: block;
    width:100%;
    height: 100%;
    background-color: #fff;
    position:absolute;
    top:0;
    left:calc(50% + 400px);
    @include mq-sp{
      width:pxToVw(200px);
      left:pxToVw(1748px);
    }
  }
}

.work_name-list{
  width:100%;
  display: flex;
  letter-spacing: 0.05em;
  margin-top:20px;
  @include mq-sp{
    margin-top:pxToVw(40px);
  }
}

.work_name-list-item{
  width:800px;
  font-size: 1.4rem;
  font-weight: 700;
  color:#fff;
  mix-blend-mode: difference;
  padding-left: 25px;
  @include mq-sp{
    width:100%;
    font-size: pxToVw(36px);
    line-height: 1.3;
    padding-left:pxToVw(50px);
    padding-right: pxToVw(178px);
  }
  a{
    display:block;
    width:100%;
    color:#fff;
    @include hoverOpacity;
  }
  span{
    font-weight: 400;
    display: inline-block;
    @include mq-sp{
      margin-top:pxToVw(20px);
    }
  }
}

//*******************************************************************
// INTRO
//*******************************************************************

.intro{
  background-color: #0e1b25;
  position:relative;
  @include img-bg("../img/top/hello-bg.png", 1440px,655px);
  height:455px;
  background-size: cover;
}

.intro_l-inner{
  width:$minW;
  height: 718px;
  margin:0 auto;
  padding-top:68px;
  //padding-bottom:118px;
  position:relative;
  z-index:1;
  @include mq-sp{
    width:100%;
    height: pxToVw(1448px);
    padding-top:15.2vw;
    //padding-bottom:pxToVw(140px);
  }
}

.intro_txt-wrap{
  //width:500px;
  //margin-left: 500px;
  font-size: 1.2rem;
  font-family:'PressStart2P';
  line-height: 1.6;
  letter-spacing: 0.05em;
  color:#fff;
  @include mq-sp{
    //width:pxToVw(927px);
    width:pxToVw(956px);
    margin:0 auto;
    font-size: pxToVw(30px);
    letter-spacing: 0;
    position:relative;
    z-index:1;
  }
  .intro_hello{
    font-size: 1.4rem;
    margin-bottom:35px;
    line-height: 1.9em;
    @include mq-sp{
      font-size: pxToVw(36px);
      letter-spacing: -0.05em;
      margin-bottom:pxToVw(102px);
    }
    span{
      display: block;
      font-size: 4.4rem;
      margin-top:10px;
      margin-bottom:37px;
      @include mq-sp{
        font-size: 6.26667vw;
        letter-spacing: 0;
        margin-top: pxToVw(10px);
        margin-bottom:6.6vw;
      }
    }
  }
  .intro_txt{
    //margin-bottom:30px;
    margin-bottom:21px;
    @include mq-sp{
      margin-bottom: pxToVw(50px);
      letter-spacing: -0.037em;
    }
    &:last-of-type{
      margin-bottom:0;
    }
    a {
      display: inline-block;
      color: #fff;
      //text-decoration: underline;
      padding:0 15px;
      //margin-bottom: 30px;
      margin-bottom: 22px;
      position:relative;
      @include hoverOpacity;
      @include mq-sp{
        padding:0 pxToVw(30px);
        margin-bottom: pxToVw(50px);
      }
      &::after{
        content:"";
        display: block;
        width:100%;
        height: 1px;
        background-color: #fff;
      }
    }
  }
  .c-orange{
    display: inline-block;
    color:#ff9c00;
    margin:0 15px;
    @include mq-sp{
      margin:0 pxToVw(30px);
    }
    &:first-of-type{
      margin-left: 0;
    }
    &.not{
      margin-right: 0;
      position:relative;
      &::after{
        content:"";
        display: block;
        width:36px;
        height: 1px;
        background-color: #ff9c00;
        position:absolute;
        top:0;
        bottom:0;
        margin:auto;
      }
    }
  }
  .dotline{
    display: block;
    &:first-of-type{
      //margin-bottom:30px;
      margin-bottom:20px;
      @include mq-sp{
        margin-bottom: pxToVw(50px);
      }
    }
  }
}

//.intro_arrow{
//  width:548.5px;
//  position:absolute;
//  bottom:-114px;
//  left:-216.5px;
//  transform-origin: left bottom;
//  transform: rotate(0deg);
//  @include mq-sp{
//    width:pxToVw(551px);
//    bottom:pxToVw(-119px);
//    left:0;
//  }
//}

.intro_arrow-wrap{
  width:100%;
  min-width: 1440px;
  position:absolute;
  bottom:-112px;
  left:50%;
  transform: translateX(-50%);
  @include mq-sp{
    bottom:pxToVw(-117px);
    left:0;
    transform: translateX(0);
  }
}

.intro_arrow{
  width:548.5px;
  transform-origin: 50px bottom;
  transform: rotateZ(-100deg);
  transition: transform .3s cubic-bezier(0.215, 0.61, 0.355, 1);
  //position:absolute;
  //bottom:-112px;
  //left:0px;
  //transform-origin: left bottom;
  //transform: rotate(0deg);
  @include mq-sp{
    width:pxToVw(551px);
    transform-origin: left bottom;
    //bottom:pxToVw(-119px);
    //left:0;
  }
  &.active{
    transform: rotateZ(0deg);
  }
}

//*******************************************************************
// DOMAIN
//*******************************************************************

.domain{
  padding-top:148px;
  @include mq-sp{
    padding-top:pxToVw(150px);
  }
}

.domain_l-ttl-wrap{
  width:$minW;
  margin:0 auto;
  margin-bottom:71.5px;
  overflow: hidden;
  @include mq-sp{
    width:pxToVw(1025px);
    margin-bottom:pxToVw(150px);
  }
}

.domain_ttl{
  width:528px;
  margin:0 0 0 auto;
  //padding-bottom:71.5px;
  //margin-bottom:71.5px;
  position:relative;
  opacity: 0;
  @include mq-sp{
    width:pxToVw(683px);
    //padding-bottom:pxToVw(150px);
  }
  //&::after{
  //  content:"";
  //  display: block;
  //  width:100%;
  //  height: 100%;
  //  background-color: #fff;
  //  position:absolute;
  //  top:0;
  //  left:0;
  //  transform-origin: right center;
  //  transform: scaleX(1);
  //  transition: transform .55s cubic-bezier(0.215, 0.61, 0.355, 1);
  //}
  //&.active{
  //  &::after{
  //    transform: scaleX(0);
  //  }
  //}
}

.domain_item{
  margin-bottom:50px;
  position:relative;
  @include mq-sp{
    margin-bottom:pxToVw(60px);
  }
  &:last-of-type{
    margin-bottom:0;
  }
  &.web{
    @include img-bg('/assets/img/top/domain-web-bg_pc.jpg',1440px,650px,100%,671px);
    background-position: center bottom;
    @include mq-sp{
      @include img-bg('/assets/img/top/domain-web-bg_sp.jpg',100%,100vw,100%,auto);
      background-position: left pxToVw(34px);
    }
  }
  &.interactive{
    @include img-bg('/assets/img/top/domain-interactive-bg_pc.jpg',1219.5px,650px,100%,671px);
    background-position: calc(50% + 110px) bottom;
    @include mq-sp{
      @include img-bg('/assets/img/top/domain-interactive-bg_sp.jpg',100%,100vw,100%,auto);
      background-position: left pxToVw(147px);
    }
  }
  &.electronics{
    @include img-bg('/assets/img/top/domain-electronics-bg_pc.jpg',1219.5px,650px,100%,671px);
    background-position: calc(50% - 110px) bottom;
    @include mq-sp{
      @include img-bg('/assets/img/top/domain-electronics-bg_sp.jpg',100%,100vw,100%,auto);
      background-position: left pxToVw(37px);
    }
  }
  &::after{
    content:"";
    display: block;
    width:100%;
    height: 100%;
    background-color: #fff;
    position:absolute;
    top:0;
    left:0;
    transform-origin: right center;
    transform: scaleX(1);
    transition: transform .55s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  &:nth-of-type(even){
    &::after{
      transform-origin: left center;
    }
  }
  &.active{
    &::after{
      transform: scaleX(0);
    }
  }
}

.domain_item-l-inner{
  width:$minW;
  margin:0 auto;
  position: relative;
  @include mq-sp{
    width:pxToVw(1025px);
  }
}


.domain_item-ttl{
  //position:relative;
  z-index:1;
  opacity: 0;
  position:absolute;
  top:0;
  left:0;
  @include mq-sp{
    position: relative;
  }
  .web &{
    width:420.5px;
    @include mq-sp{
      width:pxToVw(722px);
    }
  }
  .interactive &{
    width:830.5px;
    margin:0 0 0 auto;
    right:0;
    @include mq-sp{
      width:pxToVw(727px);
      margin:0 auto 0 0;
    }
  }
  .electronics &{
    width:383px;
    @include mq-sp{
      width:pxToVw(666px);
    }
  }
}

.domain_item-info{
  width:500px;
  padding:45px 50px 53px 50px;
  color:#0e1b25;
  letter-spacing: 0.05em;
  background-color: #fff;
  position:absolute;
  @include mq-sp{
    width:pxToVw(1025px);
    padding:pxToVw(78px) pxToVw(96px) pxToVw(78px) pxToVw(96px);
    position:static;
  }
  .web &{
    top:146px;
    right:0;
    @include mq-sp{
      margin-top: pxToVw(934px);
    }
  }
  .interactive &{
    top:146px;
    left:50px;
    @include mq-sp{
      margin-top: pxToVw(940px);
    }
  }
  .electronics &{
    top:146px;
    right:47px;
    @include mq-sp{
      margin-top: pxToVw(936px);
    }
  }
}

.domain_item-info-ttl{
  font-size: 3rem;
  line-height: 1.6;
  font-weight: 900;
  margin-bottom:32px;
  @include mq-sp{
    font-size: pxToVw(60px);
    line-height: 1.5;
    margin-bottom: pxToVw(74px);
  }
}

.domain_item-info-txt{
  font-size: 1.4rem;
  margin-bottom:30px;
  @include mq-sp{
    font-size: pxToVw(36px);
    margin-bottom: pxToVw(70px);
  }
  dt{
    font-weight: 700;
    line-height: 1.42;
    margin-bottom:15px;
    @include mq-sp{
      line-height: 1.66;
      margin-bottom:pxToVw(24px);
    }
  }
  dd{
    line-height: 1.7;
    @include mq-sp{
      line-height: 1.66;
    }
  }
}

.domain_item-info-btn{
  font-size: 1.8rem;
  font-family:'NotoSerif';
  @include mq-sp{
    font-size: pxToVw(36px);
  }
  a{
    display: inline-block;
    text-decoration: underline;
    padding:0 7px;
    @include hoverOpacity;
    @include mq-sp{
      padding:0 pxToVw(18px);
    }

  }
  .electronics &{
    span{
      display: inline-block;
      margin-left: 7px;
      position:relative;
      @include mq-sp{
        margin-left: 0;
      }
      &::before{
        content:"";
        width:100%;
        height: 1px;
        background-color: #0e1b25;
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        margin:auto;
      }
    }
  }
}

//*******************************************************************
// Partner
//*******************************************************************

.partner{
  padding-top:145px;
  padding-bottom:100px;
  @include mq-sp{
    padding-top:pxToVw(204px);
    padding-bottom: pxToVw(270px);
  }
}

.partner_l-inner{
  width:$minW;
  margin:0 auto;
  @include mq-sp{
    width:pxToVw(1025px);
  }
}

.partner_ttl{
  font-size:1.4rem;
  font-family: 'PressStart2P';
  margin-bottom:42px;
  height: 21px;
  @include mq-sp{
    font-size: pxToVw(36px);
    margin-bottom: pxToVw(90px);
    height: pxToVw(52px);
  }
}

.partner_list{
  width:100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  opacity: 0;
  @include mq-sp{
    justify-content: space-between;
  }
  li{
    width:300px;
    margin-right: 50px;
    margin-bottom:50px;
    @include mq-sp{
      width:pxToVw(500px);
      margin-right: 0;
      margin-bottom:pxToVw(25px);
    }
    &:nth-of-type(3n){
      margin-right: 0;
    }
  }
}






