.hd{
  width:100%;
  min-width: $minW;
  position:fixed;
  top:0;
  left:0;
  z-index: 15;
  mix-blend-mode: difference;
  //display: none;
  //opacity: 0;
  @include mq-sp{
    min-width: 100%;
    //position:fixed;
    //z-index:5;
  }
}

.hd_l-inner{
  width:100%;
  //max-width: 1336.5px;
  margin:0 auto;
  position:relative;
  //padding-top: 50px;
  mix-blend-mode: difference;
  z-index:6;
  @include mq-sp{
    max-width: 100%;
    position:fixed;
    //z-index:6;
  }
}

.hd_logo{
  width:66.5px;
  height: 29px;
  position:absolute;
  top:50px;
  left:50px;
  //left:0;
  mix-blend-mode: difference;
  @include mq-sp{
    width:pxToVw(133px);
    height: pxToVw(58px);
    top:pxToVw(50px);
    left:pxToVw(50px);
    z-index:2;
  }
  a{
    display: block;
    @include hoverOpacity;
    svg {
      width:66.5px;
      height: 29px;
      fill:#fff;
      @include mq-sp{
        width:pxToVw(133px);
        height: pxToVw(58px);
      }
    }
  }
}

.hd_nav{
  width:100%;
  @include mq-sp{
    width:100%;
    height: 100vh;
    //background-color: #001940;
    position:fixed;
    top:0;
    left:0;
    z-index:5;
    transform: translateX(100%);
    transition: transform .25s ease-out;
  }
  &.active{
    transform: translateX(0);
  }
}

.hd_menu-wrap{
  position:absolute;
  top:50px;
  right:55px;
}

.hd_menu{
  font-size: 1.8rem;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 0.05em;
  mix-blend-mode: difference;
  display: flex;
  flex-direction: column;
  position:absolute;
  top:50px;
  right:55px;
  @include mq-sp{
    display: block;
    width:100%;
    font-size: pxToVw(80px);
    text-align: center;
    top:50%;
    left:0;
    transform: translateY(-70%);
    //mix-blend-mode: difference;
    z-index:1;
  }
  li{
    margin-bottom: 58px;
    @include mq-pc{
      -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
      -o-writing-mode: vertical-rl;
      writing-mode: vertical-rl;
      display: inline-block;
    }
    @include mq-sp{
      margin-bottom: pxToVw(112px);
    }
    &:last-of-type{
      margin-bottom: 0;
    }
    a{
      display: block;
      color:#fff;
      @include hoverOpacity;
    }
  }
  &.pc{
    @include mq-sp{
      display: none;
    }
  }
}

.hd_map-btn{
  display: none;
  @include mq-sp{
    display: block;
    width:pxToVw(900px);
    background-color: #fff;
    mix-blend-mode: difference;
    position:absolute;
    left:0;
    right:0;
    //bottom:pxToVw(114px);
    bottom:pxToVw(170px);
    margin:auto;
    z-index:1;
  }
  a{
    @include mq-sp{
      display: block;
      width:100%;
      font-size: pxToVw(48px);
      font-weight: 700;
      color:#fff;
      text-align: center;
      padding:pxToVw(65px) 0;
      mix-blend-mode: difference;
      position: relative;
    }
    &::after{
      @include mq-sp{
        content:"";
        @include img-bg('/assets/img/common/other-tab-btn-icon.png',pxToVw(40px),pxToVw(40px),pxToVw(40px));
        position: absolute;
        top:pxToVw(84px);
        right:pxToVw(235px);
      }
    }
  }
}

.menu-trigger,
.menu-trigger span {
  @include mq-sp{
    display: inline-block;
    transition: all .2s;
    box-sizing: border-box;
    z-index:5;
    mix-blend-mode: difference;
  }
}
.menu-trigger {
  display: none;
  @include mq-sp{
    display: block;
    position: absolute;
    width: pxToVw(100px);
    //width:50px;
    height: pxToVw(50px);
    //height: 25px;
    top:pxToVw(50px);
    right: pxToVw(50px);
    mix-blend-mode: difference;
  }
}
.menu-trigger span {
  @include mq-sp{
    position: absolute;
    left: 0;
    width: 100%;
    height: pxToVw(10px);
    //height: 5px;
    background-color: #fff;
  }
}
.menu-trigger span:nth-of-type(1) {
  @include mq-sp{
    top: 0;
  }
}
.menu-trigger span:nth-of-type(2) {
  @include mq-sp{
    top: pxToVw(20px);
    //top:10px;
  }
}
.menu-trigger span:nth-of-type(3) {
  @include mq-sp{
    bottom: 0;
  }
}

.menu-trigger.active span:nth-of-type(1) {
  @include mq-sp{
    -webkit-transform: translateY(pxToVw(20px)) rotate(-45deg);
    transform: translateY(pxToVw(20px)) rotate(-45deg);
    //-webkit-transform: translateY(10px) rotate(-45deg);
    //transform: translateY(10px) rotate(-45deg);
  }
}
.menu-trigger.active span:nth-of-type(2) {
  @include mq-sp{
    opacity: 0;
  }
}
.menu-trigger.active span:nth-of-type(3) {
  @include mq-sp{
    -webkit-transform: translateY(pxToVw(-20px)) rotate(45deg);
    transform: translateY(pxToVw(-20px)) rotate(45deg);
    //-webkit-transform: translateY(-10px) rotate(45deg);
    //transform: translateY(-10px) rotate(45deg);
  }
}



