.ft{
  width:100%;
  min-width:$minW;
  @include mq-sp{
    min-width: 100%;
  }
}

.ft_copy{
  width:323.5px;
  font-size: 1.2rem;
  color:#fff;
  text-align: center;
  background-color: #0e1b25;
  padding:41px 0;
  margin:0 0 0 auto;
  @include mq-sp{
    width:pxToVw(731px);
    font-size: pxToVw(30px);
    padding:pxToVw(78px) 0;
  }
}